<template>
  <div>
    <post-list-top-nav />

    <v-layout row>
      <v-flex xs12 class="posts-wrap">
        <div class="posts-wrap-content ma-auto">
          <v-layout row align-center>
            <v-flex xs11>
              <div class="text-xs-center mb-2">
                <div class="text-xs-center">
                  <v-pagination
                    v-model="pagination.page"
                    :length="pages()"
                    @input="refreshPaginationPage"
                  />
                </div>
              </div>
            </v-flex>

            <v-flex xs1 d-flex class="pa-0 mb-4 mr-3">
              <v-select
                v-model="pagination.rowsPerPage"
                :items="[10, 25, 50, 100]"
                block
                hide-details
                single-line
                @input="refreshPaginationRows"
              />
            </v-flex>
          </v-layout>

          <v-card v-if="isLoading" class="pa-3">
            <v-progress-linear
              color="white"
              indeterminate
              rounded
              height="3"
            />
          </v-card>

          <div v-else>
            <v-card class="pa-3">
              <div v-if="posts && posts.length > 0" class="posts-list">
                <div
                  v-for="(post, index) in posts"
                  :key="post.id"
                  class="posts-list-item"
                  :class="{ deleted: deletedPost.includes(post.id) }"
                >
                  <div class="post-content-top">
                    <v-layout row>
                      <v-flex>
                        <v-layout align-center justify-start row class="pa-2">
                          <router-link :to="`/moderation/user_list/user/${post.user.id}`" class="user-link">
                            <v-avatar v-if="post.user.image" size="60px">
                              <img :src="post.user.image" alt="">
                            </v-avatar>
                            <v-avatar v-else size="60px">
                              <v-icon class="no-avatar-icon">
                                mdi-account
                              </v-icon>
                            </v-avatar>

                            <div
                              class="title text--darken-1 ml-3 wrap-text"
                              v-text="post.user.username"
                            ></div>
                          </router-link>
                          <div
                            v-if="post.isNew"
                            class="new--text red--text font-size-18 ml-3"
                          >
                            New !
                          </div>
                        </v-layout>

                        <div class="tag-list mb-2">
                          <span
                            v-for="(tag, indexTag) in post.tags"
                            :key="indexTag"
                            class="font-size-15 bold deep-orange--text mr-3"
                            v-text="tag.title"
                          >
                          </span>
                        </div>

                        <div v-if="post.statistic">
                          {{ post.statistic.count_comments }} Comments
                        </div>
                      </v-flex>

                      <v-flex xs6>
                        <div
                          class="text-xs-right mb-5 pb-1 v-btn--block justify-end"
                        >
                          <v-btn
                            color="warning"
                            class="ma-0"
                            @click="openDeletePostModal(post.id)"
                          >
                            Delete
                          </v-btn>
                          <v-btn
                            color="error"
                            class="ma-0 ml-2"
                            @click="openForeverDeleteModal(post.id)"
                          >
                            Delete forever
                          </v-btn>
                        </div>

                        <div
                          class="text-xs-right"
                          v-text="timePassed(post.created_at)"
                        ></div>
                      </v-flex>
                    </v-layout>

                    <v-layout row>
                      <v-flex xs12 class="mb-4">
                        <a
                          :href="createUrl(post.slug)"
                          class="headline mb-4 wrap-text post-link"
                          target="_blank"
                          @click="postChangeVisited(post.id, index)"
                          v-text="post.title"
                        ></a>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div class="post-content">
                    <div v-if="post.chunks && post.chunks.one_content">
                      <div
                        v-if="post.chunks.one_content.type === 'image'"
                        class="post-item mb-3"
                      >
                        <div class="image-block">
                          <a
                            :href="createUrl(post.slug)"
                            target="_blank"
                            @click="postChangeVisited(post.id, index)"
                          >
                            <img :src="post.chunks.one_content.url" alt="">
                          </a>
                        </div>
                      </div>

                      <div
                        v-if="
                          post.chunks.one_content.type === 'video' ||
                            post.chunks.one_content.type === 'gif'
                        "
                        class="post-item mb-3"
                      >
                        <div class="video-block">
                          <a :href="createUrl(post.slug)" target="_blank" @click="postChangeVisited(post.id, index)">
                            <video :poster="post.chunks.one_content.preview">
                              <source
                                v-if="post.chunks.one_content.web_url"
                                :src="post.chunks.one_content.web_url"
                                type="video/webm"
                              >
                              <source
                                :src="post.chunks.one_content.url"
                                type="video/mp4"
                              >
                            </video>
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="post.chunks.one_content.type === 'iframe'"
                        class="post-item mb-3"
                      >
                        <div class="iframe-block">
                          <a :href="createUrl(post.slug)" target="_blank" @click="postChangeVisited(post.id, index)">
                            <iframe
                              :src="
                                `https://www.youtube.com/embed/${youtubeUrl(
                                  post.chunks.one_content.url
                                )}`
                              "
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div v-if="post.chunk_description" class="post-item mb-3 ">
                      <a
                        :href="createUrl(post.slug)"
                        class="text-block wrap-text post-link"
                        target="_blank"
                        @click="postChangeVisited(post.id, index)"
                        v-text="post.chunk_description"
                      ></a>
                    </div>
                  </div>
                  <v-layout align-center justify-start row>
                    <v-flex xs4>
                      <span>
                        <v-icon class="blue-grey--text mr-2">thumb_up</v-icon>
                        <span
                          class="teal--text bold font-size-18"
                          v-text="post.statistic.upvotes"
                        >
                        </span>
                      </span>

                      <span class="ml-3">
                        <v-icon class="blue-grey--text mr-2">thumb_down</v-icon>
                        <span
                          class="red--text bold font-size-18"
                          v-text="post.statistic.downvotes"
                        >
                        </span>
                      </span>
                    </v-flex>
                    <v-flex xs4 class="center-align">
                      <v-btn
                        :href="createUrl(post.slug)"
                        flat
                        large
                        class="blue-grey--text bold"
                        target="_blank"
                        @click="postChangeVisited(post.id, index)"
                      >
                        See all
                      </v-btn>
                    </v-flex>
                    <v-flex xs4 class="text-xs-right">
                      <span>
                        <v-icon class="blue-grey--text mr-2">videocam</v-icon>
                        <span
                          class="blue-grey--text bold font-size-18"
                          v-text="post.statistic.count_videos"
                        >
                        </span>
                      </span>

                      <span class="ml-3">
                        <v-icon class="blue-grey--text mr-2">panorama</v-icon>
                        <span
                          class="blue-grey--text bold font-size-18"
                          v-text="post.statistic.count_images"
                        >
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>

                  <div class="deleted-block">
                    <v-btn
                      :loading="post.id === restoreLoading"
                      color="warning"
                      @click="restoreCurrentPost(post.id)"
                    >
                      Restore
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-else class="posts-list">
                Not found posts
              </div>
            </v-card>
            <v-layout row align-center>
              <v-flex xs11>
                <div class="text-xs-center mb-2">
                  <div class="text-xs-center">
                    <v-pagination
                      v-model="pagination.page"
                      :length="pages()"
                      :total-visible="10"
                      @input="refreshPaginationPage"
                    />
                  </div>
                </div>
              </v-flex>

              <v-flex xs1 d-flex class="pa-0 mb-4 mr-3">
                <v-select
                  v-model="pagination.rowsPerPage"
                  :items="[10, 25, 50, 100]"
                  block
                  hide-details
                  single-line
                  @input="refreshPaginationRows"
                />
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <delete-post-modal
      v-model="deletePostModal"
      :post-id="postId"
      :reason="reason"
      @deleteCurrentPost="deleteCurrentPost"
    />
    <accept-modal
      v-model="showAcceptModal"
      :post-id="postId"
      @accept="deleteForeverPost"
    />
  </div>
</template>

<script>
import { BaseUrl } from "../../../../../config"
import moment from "moment"
import { log } from "../../../../../utils/helpers"
import PostListTopNav from "../PostListTopNav"
import DeletePostModal from "../../../../../components/Moderation/PostList/DeletePostModal"
import { getAllPostsRequest } from "../../../../../api/posts"
import { postRestore, postChangeVisitedStatus } from "../../../../../api/post"
import mixinYoutubeUrl from "../../../../../mixins/mixinYoutubeUrl"
import SavesPagination from "../../../../../mixins/SavesPaginationMixin"
import { fetchData } from "../../../../../utils/response"
import { getReasons } from "../../../../../api/reason"
import AcceptModal from "@/components/Modals/AcceptModal"

export default {
  name: "AllPostsList",
  components: {
    DeletePostModal,
    PostListTopNav,
    AcceptModal,
  },
  mixins: [SavesPagination, mixinYoutubeUrl],
  data() {
    return {
      deletePostModal: false,
      postId: null,
      deletedPost: [],
      restoreLoading: null,
      isLoading: false,
      BaseUrl: "",
      search: null,
      total: 0,
      pagination: {},
      posts: {},
      reason: [],
      showAcceptModal: false,
    }
  },
  computed: {
    // pages() {
    //   if (this.pagination.rowsPerPage == null || this.total == null) return 0;
    //
    //   return Math.ceil(this.total / this.pagination.rowsPerPage);
    // }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    total() {
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    }
    // pagination(pagination) {
    //   this.$paginationCacheSave(pagination.page, pagination.rowsPerPage);
    //   this.refresh();
    // }
  },
  mounted() {
    this.BaseUrl = BaseUrl
  },
  created() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
    this.getReasonBlockUser()
    this.refresh()
    this.$bus.on("post-search-input", this.onSearchPost)
  },
  destroyed() {
    this.search = null
    this.$bus.off("post-search-input", this.onSearchPost)
  },
  methods: {
    openForeverDeleteModal(id){
      this.postId = id
      this.showAcceptModal = true
    },
    deleteForeverPost(){
      this.refresh()
      this.$notificationShow(
          `The post is successfully deleted`
      )
    },
    createUrl(slug) {
      return `${this.BaseUrl}post/${slug}`
    },
    openDeletePostModal(id) {
      this.postId = id
      this.deletePostModal = true
    },
    async getReasonBlockUser() {
      this.reason = await getReasons()
        .then(response => {
          return response.data.data
        })
        .then(data =>
          data.map(reason => {
            return reason
          })
        )
        .catch(this.$notificationHandleResponseError)
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        search: this.search
      }
      this.isLoading = true
      this.posts = await getAllPostsRequest(params)
        .then(response => {
          log(response)
          this.total = response.data.meta.total
          this.$bus.emit(
            "new-posts-count",
            response.data.additional.new_posts_count
          )
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(post => {
            return post
          })
        )
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    onSearchPost(searchPostValue) {
      this.search = searchPostValue
      this.refreshPaginationRows()
    },
    refreshPaginationPage() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    refreshPaginationRows() {
      this.pagination.page = 1
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    timePassed(create) {
      return moment(create).fromNow()
    },
    deleteCurrentPost(id) {
      this.deletedPost.push(id)
    },
    restoreCurrentPost(id) {
      this.restoreLoading = id
      postRestore(id)
        .then(() => {
          this.deletedPost.includes(id)
            ? this.deletedPost.splice(this.deletedPost.indexOf(id), 1)
            : false
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.restoreLoading = null))
    },
    postChangeVisited(id, index) {
      postChangeVisitedStatus(id)
        .then(() => {
          this.posts[index].isNew = 0
        })
        .catch(this.$notificationHandleResponseError)
    },
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  }
}
</script>
